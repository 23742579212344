
<template>
    <v-container>
        <v-alert v-if="error" type="error">{{ error }}</v-alert>

        <v-row>
            <v-col cols="12" md="12">
                <v-text-field v-model="orderId" label="Order ID"></v-text-field>
                <v-btn @click="fetchOrderDetails" color="primary">Bestellung laden</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="orderDetails">
            <v-col cols="12">
                <v-card :loading="loading">
                    <v-card-title>Order Details</v-card-title>
                    <v-card-text>
                        <div class="orderDetails">
                            <p><strong>Order ID:</strong> {{ orderDetails.orderId }}</p>
                            <p><strong>Shop:</strong> {{ orderDetails.shop }}</p>
                            <p><strong>Create Date:</strong> {{ formatDate(orderDetails.createDate) }}</p>
                            <p><strong>State:</strong> {{ orderDetails.state }}</p>
                            <p><strong>Customer:</strong> {{ orderDetails.customer.firstName }} {{ orderDetails.customer.lastName }}</p>
                            <p><strong>Shipping Type:</strong> {{ orderDetails.shipping.type }}</p>
                            <p><strong>Shipment Date:</strong> {{ formatDate(orderDetails.shipping.shipmentDate) }}</p>
                            <p><strong>Last Shipment Date:</strong> {{ formatDate(orderDetails.shipping.lastShipmentDate) }}</p>
                            <p><strong>Products:</strong></p>
                            <ul>
                                <li v-for="product in orderDetails.products" :key="product.sku">
                                    <p><strong>Name:</strong> {{ product.name }}</p>
                                    <p><strong>SKU:</strong> {{ product.sku }}</p>
                                    <p><strong>Attributes:</strong></p>
                                    <ul>
                                        <li v-for="attr of Object.keys(product.attributes)" :key="attr">{{ attr }}: {{ product.attributes[attr] }}</li>
                                    </ul>
                                    <p><strong>Quantity:</strong> {{ product.qty }}</p>

                                </li>
                            </ul>
                        </div>


                      
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" v-if="orderDetails.products.filter(x => x.attributes && x.attributes.designId).length == 0">
                <v-card>
                    <v-card-title>Bestellung importieren?</v-card-title>
                    <v-card-text>
                        <p>Stelle sicher, dass die SKU bereits mit einem Design verknüpft wurde</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" @click="importOrder">Bestellung Importieren</v-btn>
                        </v-card-actions>
                </v-card>
            </v-col>

            <v-col cols="12" v-for="product of orderDetails.products.filter(x => x.attributes && x.attributes.designId)" :key="product.attributes.designId" >
                <v-row>

                <v-col cols="6" v-if="product.design">
                    <v-img style="background:magenta;" :ref="product.design.options.designId" :src="product.design.options.poster + '?' + new Date().getTime()" contain max-height="700px"></v-img>
                </v-col>
                <v-col cols="6" v-if="product.design">
                    <v-text-field label="DesignId" v-model="product.design.designId" readonly></v-text-field>
                    <v-text-field label="SKU" v-model="product.design.sku" readonly></v-text-field>
                    
                    <v-text-field  v-for="field in fields.filter(x => product.design.options[x])" :key="field" :label="field" v-model="product.design.options[field]"></v-text-field>
                    <v-btn color="green"  @click="saveChanges(product.design.options)">Speichern & Neu erzeugen</v-btn>
                    <v-btn v-if="product.design.options.pdf" color="primary" class="ma-2" :href="product.design.options.pdf">PDF Herunterladen</v-btn>
                    <v-btn v-if="product.design.options.poster" color="primary" class="ma-2" :href="product.design.options.poster">PNG Herunterladen</v-btn>
                    <v-btn v-if="product.design.options.jpg" color="primary" class="ma-2" :href="product.design.options.jpg">JPG Herunterladen</v-btn>
                    <v-btn color="primary" class="ma-2" @click="designPreviewUrl(product.attributes.designId)" target="_blank">Neu Generieren</v-btn>

                </v-col>
            </v-row>
            </v-col>
        </v-row>

       
        <!-- loading overlay -->
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

    </v-container>
</template>

                           
<script>

import DesignService from '../../services/DesignService'
export default {

    data() {
        return {
            loading: false,
            error: null,
            orderId: '',
            orderDetails: null,
            showSaveButton: false,
            fields: ["line1", "line2", "line3", "line4", "line5", "line6", "line7", "line8", "line9", "line10", "image1", "image2", "image3", "image4", "image5", "image6", "image7", "image8", "image9", "image10", "color1", "color2", "color3", "color4", "color5", "color6", "color7", "color8", "color9", "color10"]
        }
    },

    computed: {
        formattedOrderDetails() {
            if (!this.orderDetails) {
                return null;
            }

            const formattedProducts = this.orderDetails.products.map((product) => {
                return {
                    name: product.name,
                    sku: product.sku,
                    attributes: product.attributes,
                    qty: product.qty,
                };
            });

            return {
                orderId: this.orderDetails.orderId,
                shop: this.orderDetails.shop,
                createDate: this.formatDate(this.orderDetails.createDate),
                state: this.orderDetails.state,
                customer: {
                    firstName: this.orderDetails.customer.firstName,
                    lastName: this.orderDetails.customer.lastName,
                },
                shipping: {
                    type: this.orderDetails.shipping.type,
                    shipmentDate: this.formatDate(this.orderDetails.shipping.shipmentDate),
                    lastShipmentDate: this.formatDate(this.orderDetails.shipping.lastShipmentDate),
                },
                products: formattedProducts,
            };
        },
    },

    created() {
        this.orderId = this.$route.query.orderId
        console.log("Found order in url: ", this.orderId)
        if (this.orderId) {
            this.fetchOrderDetails()
        }
    },


    methods: {

        formatDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString();
        },


        async fetchOrderDetails() {
            this.loading = true;
            try {

                // Make API call to fetch order details using orderId
                const orderDetails = await DesignService.getOrder(this.orderId)

                //add orderId to url query orderId
                this.$router.push({ query: { orderId: this.orderId } })


                for (let product of orderDetails.data.products) {
                    if (product.attributes.designId) {
                        product.design = {
                            options: (await DesignService.getDesign(product.attributes.designId)).data,
                            designId: product.attributes.designId,
                            sku: product.sku
                        }
                    }
                }

                this.orderDetails = orderDetails.data
            } catch (err) {
                this.error = err;
            } finally {
                this.loading = false;
            }
        },

        async saveChanges(design) {
            try {
                this.loading = true;
                await DesignService.saveDesign(design.designId, design)
                await this.designPreviewUrl(design.designId)

                let image = this.orderDetails.products.filter(x => x.design.options.designId === design.designId)[0].design.options.poster

                this.$refs[design.designId][0].src = null;

                setTimeout(() => {
                    //ref designId img element reload
                    this.$refs[design.designId][0].src = image
                }, 1500);
                this.showSaveButton = false;
            } catch (err) {
                this.error = err;
            } finally {
                this.loading = false;
            }
        },

        async designPreviewUrl(designId) {
            // Make API call to fetch design preview url using designId
            const preview = await DesignService.generateOrder(designId)

            //find designId in orderDetails.products.attributes.designId

            for (let p of this.orderDetails.products) {
                if (p.attributes['designId'] === designId) {
                    console.log(p)
                    p.design = preview.data
                }
            }

            console.log(preview.data.options.preview)
            return preview.data.options.preview;
        },

        async importOrder() {
            let orderId = this.orderId
            try {
                this.loading = true;
                await DesignService.importOrder(orderId)
                await this.fetchOrderDetails()
            } catch (err) {
                this.error = err
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style scoped>
.orderDetails>p {
    margin: 0px;
    padding: 0px;
}
</style>
