<template>
  <div>
    <v-container>
      <v-alert type="error" v-if="error" :value="true">{{ error }}</v-alert>

      <v-row>
        <v-col cols="10">
          <v-text-field v-model="search" label="Suche" single-line hide-details></v-text-field>
        </v-col>

      </v-row>

      <v-row>
        <!-- Vuetify Data Table to show all posters-->
        <v-col>

          <v-data-table :search="search" :headers="headers" :items="designs" :loading="loading" :items-per-page="50" class="elevation-1">


            <template v-slot:item.state="{ item }">
              <v-chip class="ma-1" v-for="tag in item.state.split(',')" :key="tag" small>{{ tag }}</v-chip>
            </template>
            <template v-slot:item.customer="{ item }">

              <span> {{ item.customer.firstName }} {{ item.customer.lastName }}</span><br />
              <span> {{ item.customer.adress }} </span><br />
              <span> {{ item.customer.zip }} {{ item.customer.city }} </span><br />
              <span> {{ item.customer.country }} </span>

            </template>

            <template v-slot:item.date="{ item }">
              {{ item.date | moment("DD.MM.YYYY HH:mm") }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="showItem(item)">
                mdi-eye
              </v-icon>
            </template>


          </v-data-table>

        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="design">
      <!-- dialog with details of order-->
      <v-card v-if="design">
        <v-card-title class="headline">Bestellung</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field label="Bestellnummer" v-model="design.orderId" readonly></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="plentyId" v-model="design.plentyId" readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field label="Status" v-model="design.state" readonly></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Datum" v-model="design.created" readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field label="Kunde" v-model="design.customer.firstName" readonly></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Kunde" v-model="design.customer.lastName" readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field label="Kunde" v-model="design.customer.adress" readonly></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="PLZ" v-model="design.customer.zip" readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field label="Kunde" v-model="design.customer.city" readonly></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Kunde" v-model="design.customer.country" readonly></v-text-field>
            </v-col>
          </v-row>

          <v-row v-for="p of design.products" :key="p.design.designId">
            <v-col cols="6">
              <v-img :src="p.design.poster" contain max-height="400px"></v-img>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Produkt" v-model="p.name" readonly></v-text-field>
              <v-text-field label="DesignId" v-model="p.design.designId"></v-text-field>

              <!-- Download link to poster-->
              <v-btn color="primary" class="mr-2" @click="generateOrder(p.design.designId)" target="_blank">Neu Generieren</v-btn>
              <v-btn color="primary" :href="p.design.poster" target="_blank">Download</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <AuthCheck />
  </div>
</template>

<script>
import DesignService from "../../services/DesignService";
import AuthCheck from "../../components/AuthCheck.vue";
import AuthService from "../../services/AuthService";

export default {
  components: { AuthCheck },
  data: () => ({
    designs: [],
    design: null,
    loading: false,
    error: null,
    user: null,
    search: "",
    testFile: null,
    headers: [
      {
        text: "Bestellnummer",
        align: "start",
        sortable: false,
        value: "orderId",
      },
      { text: "plentyId", value: "plentyId" },
      { text: "Status", value: "state" },
      { text: "Kunde", value: "customer" },
      { text: "Datum", value: "created" },
      { text: "Aktionen", value: "actions" },
    ],
  }),
  async created() {
    try {
      this.user = (await AuthService.getUser()).data;
    } catch (err) {
      console.error(err);
      if (!this.user) this.$router.push("/auth/login");
    }
  },
  async mounted() {
    this.loading = true;
    try {
      this.designs = (await DesignService.getOrders()).data;
    } catch (err) {
      this.error = err;
    } finally {
      this.loading = false;
    }
  },
  methods: {

    showItem(item) {
      this.design = item;
    },

    async generateOrder(designId) {
      this.loading = true;
      try {

        await DesignService.generateOrder(designId);
        this.design = await DesignService.getOrder(this.design.orderId);
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    }

  },
};
</script>

<style></style>