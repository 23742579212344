<template>
    <div>
        <v-container>
            <v-alert type="error" v-if="error" :value="true">{{ error }}</v-alert>

            <v-row>
                <v-col cols="10">
                    <v-text-field v-model="search" label="Suche" single-line hide-details></v-text-field>
                </v-col>
                <v-col cols="2">
                    <v-btn color="primary" block @click="createPoster">Poster erstellen</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <!-- Vuetify Data Table to show all posters-->
                <v-col cols="12">
                    <v-data-table :search="search" :headers="headers" :items="posters" :loading="loading" :items-per-page="50" class="elevation-1">

                        <template v-slot:item.thumbnail="{ item }" >
                            <v-img :src="item.thumbnail" :width="120" :height="120" @click="loadPreview(item)"></v-img>
                        </template>

                        <template v-slot:item.date="{ item }">
                            Aktualisiert am {{item.updated | moment("DD.MM.YYYY HH:mm") }} <br>
                            Erstellt am {{item.created | moment("DD.MM.YYYY HH:mm") }}
                        </template>

                        
                        <template v-slot:item.tags="{ item }">
                            <v-chip class="ma-1" v-for="tag in item.tags.split(',')" :key="tag" small>{{tag}}</v-chip>
                        </template>
                        
                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="mr-2" @click="editItem(item)">
                                mdi-pencil
                            </v-icon>

                            <!-- Duplicate -->
                            <v-icon small class="mr-2" @click="duplicateDialog(item)">
                                mdi-content-copy
                            </v-icon>

                            <v-icon small @click="toDeleteItem  = item; dialogDelete = true;">
                                mdi-delete
                            </v-icon>
                        </template>
                        <template v-slot:no-data>
                            Keine Poster gefunden
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        <AuthCheck />

        <!-- Delete Ask Modal Dialog -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card v-if="toDeleteItem">
                <v-card-title class="headline">Poster löschen</v-card-title>
                <v-card-text>Wollen Sie das Poster {{ toDeleteItem.SKU }} wirklich löschen?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete = false">Abbrechen</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItem()">Löschen</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Duplicate Modal Dialog -->
        <v-dialog v-model="dialogDuplicate" max-width="500px">
            <v-card v-if="duplicateItem">
                <v-card-title class="headline">Poster duplizieren</v-card-title>
                <v-card-text>
                    <v-text-field v-model="duplicateItem.SKU" label="SKU" required></v-text-field>
                    <v-text-field v-model="duplicateItem.title" label="Titel" required></v-text-field>
                    
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDuplicate = false">Abbrechen</v-btn>
                    <v-btn color="blue darken-1" text @click="duplicate(duplicateItem.SKU, duplicateItem.title)">Duplizieren</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import PosterService from '../../services/PosterService'
import AuthCheck from '../../components/AuthCheck.vue'
import AuthService from '../../services/AuthService'
//import SVGImage from '../../components/SVGImage.vue'

export default {
    components: { AuthCheck },
    data: () => ({
        posters: [],
        loading: false,
        error: null,
        user: null,
        search: '',
        dialogDelete: false,
        toDeleteItem: null,
        dialogDuplicate: false,
        duplicateItem: null,
        headers: [
            {
                text: 'SVG',
                align: 'start',
                sortable: false,
                value: 'thumbnail',
                filterable: false,
            },
            {
                text: 'Titel',
                align: 'start',
                sortable: false,
                value: 'title',
            },
            { text: 'SKU', value: 'SKU' },
            { text: 'Erstellt', value: 'date' },
            { text: 'Tags', value: 'tags', width: 100 },
            { text: 'Aktionen', value: 'actions', sortable: false },
        ],
    }),

    watch: {
        search() {
            //add search to query url
            this.$router.push({ query: { search: this.search } })
        },
    },

    async created() {
        try {
            this.user = (await AuthService.getUser()).data
            console.log(this.user)
        } catch (err) {
            console.error(err)
            if (!this.user) this.$router.push('/auth/login');
        }
    },
    async mounted() {
        this.loading = true
        try {

            this.posters =(await PosterService.getPosters()).data

            //get search query parameter and set it to search
            this.search = this.$route.query.search || ''
            

        } catch (err) {
            this.error = err
        } finally {
            this.loading = false
        }
    },
    methods: {
        async createPoster() {
            this.$router.push('/poster/create')
        },
        async editItem(item) {
            window.open(`/poster/create?id=${item._id}&edit=true`, '_blank');
        },

        async duplicateDialog(item) {
            this.duplicateItem = item
            this.dialogDuplicate = true
        },

        async duplicate(sku, title) {
            this.loading = true
            try {
                await PosterService.duplicatePoster(this.duplicateItem._id, sku, title)
                this.posters =(await PosterService.getPosters()).data
                this.dialogDuplicate = false
            } catch (err) {
                this.error = err
            } finally {
                this.loading = false
            }
        },

        async loadPreview(item) {
            this.loading = true
            item.thumbnail = await fetch(`https://svg.us.accelery.host/public/product/${item.SKU}/preview`, {
                headers: {
                    "x-api-key": "ayUEHgX1TV9h5X6l2ugz2MxP4NiClHVojB2rPpOL002bFd3MSfw3apX5lPp9"
                }
            }).then(res => res.text()).finally(() => this.loading = false)


        },


        async deleteItem() {
            this.loading = true
            try {
                this.dialogDelete = false;
                await PosterService.deletePoster(this.toDeleteItem._id)
                this.posters = this.posters.filter(poster => poster._id !== this.toDeleteItem._id)
            } catch (err) {
                this.error = err
            } finally {
                this.toDeleteItem = null
                this.loading = false
            }
        },

    },

}
</script>

<style>

</style>