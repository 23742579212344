<template>
  <v-card>
    <v-card-title>Font Sets</v-card-title>
    <v-card-text>
      <v-data-table :headers="headers" :items="fontSets" :items-per-page="5" class="elevation-1">
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editFontSet(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteFontSet(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="showAddFontSetDialog">Schriftset hinzufügen</v-btn>
    </v-card-actions>
    <v-dialog v-model="dialog" max-width="820px">
      <v-card>
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field v-model="editedFontSet.name" label="Name"></v-text-field>
            <v-data-table :headers="fontHeaders" :items="editedFontSet.fonts" :items-per-page="5" class="elevation-1">
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editFont(item)">mdi-pencil</v-icon>
                <v-icon small @click="deleteFont(item)">mdi-delete</v-icon>
              </template>
            </v-data-table>
            <v-btn color="primary" @click="showAddFontDialog">Schriftart Hinzufügen</v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="saveFontSet">Speichern</v-btn>
          <v-btn color="primary" @click="closeDialog">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="fontDialog" max-width="500px">
      <v-card>
        <v-card-title>{{ fontDialogTitle }}</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-autocomplete v-model="editedFont.name" :items="fonts" label="Name"></v-autocomplete>
            <v-text-field v-model="editedFont.externalName" label="External Name"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="saveFont">Speichern</v-btn>
          <v-btn color="primary" @click="closeFontDialog">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Overlay -->
    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-overlay>
  </v-card>
</template>

<script>

import FontService from '@/services/FontService';

export default {
    data() {
        return {
            loading: false,
            headers: [
                { text: 'Id', value: 'id' },
                { text: 'Name', value: 'name' },
                { text: 'Amount of Fonts', value: 'fonts.length' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            fontHeaders: [
                { text: 'Id', value: 'id' },
                { text: 'Name', value: 'name' },
                { text: 'External Name', value: 'externalName' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            fontSets: [
            ],
            dialog: false,
            fontDialog: false,
            editedFontSet: {
                _id: null,
                name: '',
                fonts: [],
            },
            editedFont: {
                id: 0,
                name: '',
                externalName: '',
            },
            defaultFontSet: {
                id: null,
                name: '',
                fonts: [],
            },
            fonts: [],
        };
    },

    created() {
        this.loadData();
    },

    methods: {

        async loadData() {
            try {
                this.loading = true;
                this.fonts = (await FontService.getFonts()).data;
                this.fontSets = (await FontService.getFontSets()).data;
            } catch (err) {
                this.error = err
            } finally {
                this.loading = false;
            }
        },
        showAddFontSetDialog() {
            this.dialogTitle = 'Schriftset hinzufügen';
            this.editedFontSet = Object.assign({}, this.defaultFontSet);
            this.dialog = true;
        },
        editFontSet(item) {
            this.dialogTitle = 'Schriftset bearbeiten';
            this.editedFontSet = Object.assign({}, item);
            this.dialog = true;
        },

        async deleteFontSet(item) {
            try {
                this.loading = true;
                await FontService.deleteFontSet(item._id);
                return this.loadData();
            } catch (err) {
                this.error = err
            } finally {
                this.loading = false;
            }
        },
        showAddFontDialog() {
            this.fontDialogTitle = 'Schriftart hinzufügen';
            this.editedFont = Object.assign({}, this.defaultFont);
            //get amount of fonts in set and set id
            this.editedFont.id = this.editedFontSet.fonts.length + 1;
            this.editedFont.new = true;
            this.fontDialog = true;
        },
        editFont(item) {
            this.fontDialogTitle = 'Schriftart bearbeiten';
            this.editedFont = Object.assign({}, item);
            this.editedFont.new = false;
            this.fontDialog = true;
        },
        deleteFont(item) {
            let id = item.id;
            const index = this.editedFontSet.fonts.findIndex((item) => item.id === id);
            if (index > -1) {
                this.editedFontSet.fonts.splice(index, 1);
            }
        },
        async saveFontSet() {
            console.log(this.editedFontSet);
            try {
              this.loading = true
              if(this.editedFontSet._id == null) {
                await FontService.addFontSet(this.editedFontSet);
              } else {
                await FontService.updateFontSet(this.editedFontSet._id, this.editedFontSet);
              }

            } catch(err) {
              this.error = err
            } finally {
              this.loading = false;
            }
            this.closeDialog();
        },
        closeDialog() {
            this.dialog = false;
            this.editedFontSet = Object.assign({}, this.defaultFontSet);
        },
        saveFont() {
            
            if(this.editedFont.new) {
                //add new font
                this.editedFontSet.fonts.push(this.editedFont);
            } else {
                //update existing font
                const index = this.editedFontSet.fonts.findIndex((item) => item.id === this.editedFont.id);
                this.editedFontSet.fonts.splice(index, 1, this.editedFont);
            }
            this.closeFontDialog();
        },
        closeFontDialog() {
            this.fontDialog = false;
            this.editedFont = Object.assign({}, this.defaultFont);
        },
    },
};
</script>
