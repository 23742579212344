//Poster service
//This service is responsible for all poster related requests
import Api from '@/services/Api'

export default {
   

    getFonts() {
        return Api().get('fonts')
    },

    getFontSets() {
        return Api().get('fontsets')
    },

    addFontSet(payload) {
        return Api().post('fontsets', payload)
    },

    updateFontSet(id, payload) {
        return Api().put(`fontsets/${id}`, payload)
    },

    deleteFontSet(id) {
        return Api().delete(`fontsets/${id}`)
    }

}