//Picture service
import Api from '@/services/Api'

export default {
    /**
     * Get all pictures
     * @returns {Promise}
     ***/
    getPictures() {
        return Api().get('pictures')
    },

    /**
     * Get a single picture
     * @param {String} id
     * @returns {Promise}
     ***/
    getPicture(id) {
        return Api().get(`pictures/${id}`)
    },

    /**
     * Create a new picture
     * @param {Object} payload
     * @returns {Promise}
     ***/
    createPicture(payload) {
        return Api().post('pictures/create', payload)
    },

    /**
     * Update a picture
     * @param {String} id
     * @param {Object} payload
     * @returns {Promise}
     ***/
    updatePicture(id, payload) {
        return Api().put(`pictures/${id}`, payload)
    },

    /**
     * Delete a picture
     * 
     * @param {String} id
     * @returns {Promise}
     ***/
    deletePicture(id) {
        return Api().delete(`pictures/${id}`)
    },


    generateImage(pictureId, image) {
        return Api().post(`pictures/${pictureId}/generate`, image)
    }
}
