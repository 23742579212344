import axios from 'axios'

const apiKey = "ayUEHgX1TV9h5X6l2ugz2MxP4NiClHVojB2rPpOL002bFd3MSfw3apX5lPp9";

export default () => {
    return axios.create({
      baseURL: `https://svg.us.accelery.host/api/`,
      headers: {
        "X-Api-Key": apiKey
      },
      timeout: 1000 * 190,
      withCredentials: true,
    })
  }