<template>
    <div>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-alert type="error" v-if="error" :value="true">
                            {{ error }}</v-alert>
                        <v-card-title>
                            <h1>Bild erstellen</h1>
                        </v-card-title>
                        <v-card-text>
                            <v-form>
                                <v-select v-model="picture.orientation" :items="['Portrait', 'Landscape']" label="Orientation" required></v-select>
                                <v-file-input label="Bild" accept="image/*" show-size @change="processUpload($event)"></v-file-input>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" :loading="loading" @click="edit ? updatePicture() : createPicture()">Speichern</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-card>
                        <v-card-title>
                            <h1>Vorschau</h1>
                        </v-card-title>
                        <v-card-text>
                            <canvas ref="preview" width="1000" height="1000"></canvas>
                            <v-btn @click="picture.points = []; updateCanvas();">Punkte zurücksetzen</v-btn>
                            <v-btn @click="findPoints();">Punkte finden</v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <AuthCheck />
    </div>
</template>

<script>
import PictureService from '../../services/PictureService'
import AuthCheck from '../../components/AuthCheck.vue'
export default {
    components: { AuthCheck, },
    data: () => ({
        picture: {
            orientation: '',
            points: [],
            img: ''
        },
        edit: false,
        uploadDialog: true,
        loading: false,
        error: null,
        canvas: null,
        ctx: null,
    }),
    mounted() {
        console.log(this.$route.query.id)
        if (this.$route.query.id) {
            this.edit = true;
            this.getPicture(this.$route.query.id)
        }

        this.canvas = this.$refs.preview
        this.ctx = this.canvas.getContext('2d')
        this.canvas.addEventListener("click",  (event) => {
            if (this.picture.points.length < 4) {
                let rect = this.canvas.getBoundingClientRect();
                var x = event.clientX - rect.left;
                var y = event.clientY - rect.top;

                //calculate x and y to relative percentage values
                x = x / this.canvas.width;
                y = y / this.canvas.height;

                this.picture.points.push([x, y]);
                this.updateCanvas();

            }
        })
    },
    methods: {

        processUpload(file) {
            this.picture.img = new Image();



            let reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = function () {
                this.picture.img.onload = this.updateCanvas;
                this.picture.base64 = reader.result
                this.picture.img.src = reader.result
            }.bind(this);
        },

        findPoints() {
            //search for pixels with color #FC00FF

            let imgData = this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);
            let data = imgData.data;
            let points = [];

            console.log("Data length: " + data.length)
            for (let i = 0; i < data.length; i += 4) {
                let r = data[i];
                let g = data[i + 1];
                let b = data[i + 2];
                if (Math.abs(r - 252) <= 12 && Math.abs(g - 0) <= 12 && Math.abs(b - 255) <= 12) {
                    console.log("Found point: " + r + " " + g + " " + b)
                    let x = (i / 4) % this.canvas.width;
                    let y = Math.floor((i / 4) / this.canvas.width);
                    points.push([x / this.canvas.width, y / this.canvas.height]);
                }
            }  

            console.log(points);
            
            //we need 4 points for the perspective transform. We have to sort out the points that are too close to each other
            let sortedPoints = [];
            for (let i = 0; i < points.length; i++) {
                let point = points[i];
                let tooClose = false;
                for (let j = 0; j < sortedPoints.length; j++) {
                    let sortedPoint = sortedPoints[j];
                    let distance = Math.sqrt(Math.pow(point[0] - sortedPoint[0], 2) + Math.pow(point[1] - sortedPoint[1], 2));
                    if (distance < 0.05) {
                        tooClose = true;
                        break;
                    }
                }
                if (!tooClose) {
                    sortedPoints.push(point);
                }
            }

            console.log(sortedPoints);

           
            if (sortedPoints.length == 4) {

                let firstPoint = sortedPoints[0];
                let secondPoint = sortedPoints[1];
                let thirdPoint = sortedPoints[2];
                let fourthPoint = sortedPoints[3];

                //check if firstpoint is top left
                if (firstPoint[0] > secondPoint[0] || firstPoint[1] > thirdPoint[1]) {
                    //swap first and second point
                    let temp = firstPoint;
                    firstPoint = secondPoint;
                    secondPoint = temp;
                }

                //check if third point is bottom right
                if (thirdPoint[0] < fourthPoint[0] || thirdPoint[1] < secondPoint[1]) {
                    //swap third and fourth point
                    let temp = thirdPoint;
                    thirdPoint = fourthPoint;
                    fourthPoint = temp;
                }

                sortedPoints[0] = firstPoint;
                sortedPoints[1] = secondPoint;
                sortedPoints[2] = thirdPoint;
                sortedPoints[3] = fourthPoint;

                


                this.picture.points = sortedPoints;
                this.updateCanvas();
            } else {
                console.log("Could not find 4 points")
            }

        },



        updateCanvas() {
            if (this.picture.img) {
                
                //set canvas width is fixed to 1000px and height is calculated based on the image ratio
                this.canvas.width = 1000;
                this.canvas.height = this.picture.img.height / this.picture.img.width * 1000;

                var ratio = Math.min(this.canvas.width / this.picture.img.width, this.canvas.height / this.picture.img.height);
                var newWidth = this.picture.img.width * ratio;
                var newHeight = this.picture.img.height * ratio;

                this.ctx.drawImage(this.picture.img, (this.canvas.width - newWidth) / 2, (this.canvas.height - newHeight) / 2, newWidth, newHeight);


                if(this.picture.points.length > 0) {
                    this.ctx.beginPath();
                    let topLeftX = this.picture.points[0][0] * this.canvas.width;
                    let topLeftY = this.picture.points[0][1] * this.canvas.height;

                    this.ctx.moveTo(topLeftX, topLeftY);
                    for (var i = 1; i < this.picture.points.length; i++) {
                        let x = this.picture.points[i][0] * this.canvas.width;
                        let y = this.picture.points[i][1] * this.canvas.height;
                        this.ctx.lineTo(x, y);
                    }
                    this.ctx.closePath();
                    this.ctx.lineWidth = 1;
                    this.ctx.strokeStyle = 'red';
                    this.ctx.stroke();
                }
            }
        },

        async getPicture(id) {
            this.loading = true
            try {
                let response = (await PictureService.getPicture(id)).data;
                this.picture = response
            } catch (err) {
                this.error = err
            } finally {
                this.loading = false
            }
        },
        async createPicture() {
            this.loading = true
            try {

                let payload = {
                    orientation: this.picture.orientation,
                    points: this.picture.points,
                    image: this.picture.base64
                }

                let response = (await PictureService.createPicture(payload)).data;
                if (response.acknowledged) {

                    this.$router.push('/pictures')
                } else {
                    this.error = 'Poster konnte nicht erstellt werden'
                }
            } catch (err) {
                let errormsg = 'Poster konnte nicht erstellt werden'
                if (err.response && err.response.data && err.response.data.error) {
                    errormsg = err.response.data.error
                }
                this.error = errormsg
            } finally {
                this.loading = false
            }
        },
        async updatePicture() {
            this.loading = true
            try {
                this.tags = this.picture.tags.split(',')
                let response = (await PictureService.updatePicture(this.picture._id, this.picture)).data;
                console.log(response)
                if (response.acknowledged) {
                    this.$router.push('/pictures')
                } else {
                    this.error = 'Poster konnte nicht gespeichert werden'
                }
            } catch (err) {
                let errormsg = 'Poster konnte nicht gespeichert werden'
                if (err.response && err.response.data && err.response.data.error) {
                    errormsg = err.response.data.error
                }
                this.error = errormsg
            } finally {
                this.loading = false
            }
        },

    },
}
</script>

<style>

</style>

<!--


document.addEventListener("DOMContentLoaded", function (event) {
    //do work

    // Variablen für das Bild und das canvas
    var img = new Image();
    var canvas = document.getElementById("canvas");
    var ctx = canvas.getContext("2d");
    console.log("test")
    // Bildpfad
    img.src = "file:///Users/christopher/Entwicklung/js/picture-generator/stock.jpeg";

    // Größe des Canvas dem Bild anpassen, wenn das Bild geladen ist
    img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
    }

    // Variablen für die gespeicherten Koordinaten
    var coords = [];

    // Funktion zum Speichern der Koordinaten, wenn auf dem Canvas geklickt wird
    canvas.addEventListener("click", function (event) {
        if (coords.length < 4) {
            var x = event.clientX - canvas.offsetLeft;
            var y = event.clientY - canvas.offsetTop;
            coords.push([x, y]);
            console.log("Koordinate gespeichert: " + x + ", " + y);
            if(coords.length == 4) {
                // draw our image
                console.log("Draw image call")
                drawImage(coords, ctx);

            }
        } 
    });
});

function drawImage(coords, ctx) {
    console.log("Drawing image")
    var artwork = new Image();
    artwork.src = "file:///Users/christopher/Entwicklung/js/picture-generator/test.png";
    
    artwork.onload = function () {
        console.log("Image loaded")
        console.log(coords)
        ctx.globalAlpha = 0.7;
        var p = new Perspective(ctx, artwork);
        p.draw(coords)
        ctx.globalAlpha = 1;
        console.log("Image draw complete")

    }
}


-->