<template>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card pa-5>
            <v-card-title class="mb-3">
              <h1>Anmelden</h1>
            </v-card-title>
            <v-alert type="error" v-if="error" :value="true">
              {{ error }}</v-alert>
            <v-form ref="form" v-model="valid" class="ma-3">
              <v-text-field
                v-model="username"
                :rules="usernameRules"
                label="Benutzername"
                required
                outlined
                color="primary"
              ></v-text-field>
              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="passwordRules"
                :type="showPassword ? 'text' : 'password'"
                label="Passwort"
                required
                outlined
                color="primary"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
              <v-row justify="center">
                <v-btn :disabled="!valid" color="primary" @click="login">
                  Anmelden
                </v-btn>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>

  import AuthService from '../services/AuthService';
  import EventBus from '../event-bus';
  export default {
    data() {
      return {
        error: null,
        username: '',
        password: '',
        showPassword: false,
        valid: false,
        usernameRules: [
          v => !!v || 'Benutzername ist erforderlich'
        ],
        passwordRules: [
          v => !!v || 'Passwort ist erforderlich'
        ]
      }
    },
    methods: {
      async login() {
        this.$refs.form.validate()
        if (this.valid) {
          try {
            let payload = {user: this.username, password: this.password}
            let response = (await AuthService.login(payload)).data
            console.log(response)
            if(response == 'Logged in') {
              EventBus.$emit('login');
              this.$router.push('/');
            }
            else this.error = "Benutzername oder Passwort falsch"
          } catch (error) {
            this.error = "Benutzername oder Passwort falsch"
          }
          
        }
      }
    }
  }
  </script>
  