<template>
    <canvas ref="canvas" :width="width" :height="height"></canvas>
</template>

<script>

export default {
    props: ['src', 'width', 'height'],
    data: () => ({
        canvas: null,
        ctx: null,
    }),
    async mounted() {
        console.log(this.$refs.canvas)
        this.canvas = this.$refs.canvas;
        console.log(this.canvas)
        this.ctx = this.canvas.getContext('2d');
        var img = new Image();
        img.onload = function () {

            console.log("loaded image");

            var ratio = Math.min(this.canvas.width / img.width, this.canvas.height / img.height);
            var newWidth = img.width * ratio;
            var newHeight = img.height * ratio;
            this.ctx.drawImage(img, (this.canvas.width - newWidth) / 2, (this.canvas.height - newHeight) / 2, newWidth, newHeight);

        }.bind(this);

        console.log(this.src)

        //check if this.src is base64 (ends with ==)

        var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;


        if (this.src.startsWith("data:image/png;base64") || this.src.startsWith("data:image/svg+xml;base64")) {
            img.src = this.src;
        } else if (base64regex.test(this.src)) {
            img.src = 'data:image/svg+xml;base64,' + encodeURIComponent(this.src);
        } else {
            //convert to base64
            console.log("needs to be converted to base64")

            img.src = 'data:image/svg+xml;base64,' + btoa(this.src);
            console.log(img.src)
        }

    },
}
</script>

<style></style>