<template>
  <div>
    <v-container>
      <v-alert type="error" v-if="error" :value="true">{{ error }}</v-alert>

      <v-row>
        <v-col cols="10">
          <v-text-field
            v-model="search"
            label="Suche"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn color="primary" block @click="createPoster"
            >Bild erstellen</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-file-input
            v-model="testFile"
            label="File input"
            prepend-icon="mdi-camera"
            multiple
            show-size
          ></v-file-input>

          <!-- Download Button -->
          <v-btn
            fab
            large
            dark
            bottom
            left
            class="v-btn--example"
            @click="downloadImages()"
            color="primary"
            style="position: fixed; bottom: 10px; left: 10px"
            >Generieren</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <!-- Vuetify Data Table to show all posters-->
        <v-col v-for="picture of pictures" :key="picture._id" cols="6">
          <v-card :loading="loading">
            <v-img
              :src="picture.image"
              height="auto"
              width="100%"
              comtain
              @click="testImage(picture)"
            ></v-img>
            <v-card-actions>
              <v-btn color="primary" @click="editItem(picture)"
                >Bearbeiten</v-btn
              >
              <v-btn color="error" @click="deleteItem(picture)">Löschen</v-btn>
            </v-card-actions>
            <v-overlay :value="loading">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <AuthCheck />
  </div>
</template>

<script>
import PictureService from "../../services/PictureService";
import AuthCheck from "../../components/AuthCheck.vue";
import AuthService from "../../services/AuthService";

export default {
  components: { AuthCheck },
  data: () => ({
    pictures: [],
    loading: false,
    error: null,
    user: null,
    search: "",
    testFile: null,
  }),
  async created() {
    try {
      this.user = (await AuthService.getUser()).data;
    } catch (err) {
      console.error(err);
      if (!this.user) this.$router.push("/auth/login");
    }
  },
  async mounted() {
    this.loading = true;
    try {
      this.pictures = (await PictureService.getPictures()).data;
    } catch (err) {
      this.error = err;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async createPoster() {
      this.$router.push("/pictures/create");
    },
    async editItem(item) {
      this.$router.push(`/pictures/create?id=${item._id}&edit=true`);
    },
    async deleteItem(item) {
      this.loading = true;
      try {
        await PictureService.deletePicture(item._id);
        this.pictures = this.pictures.filter(
          (picture) => picture._id !== item._id
        );
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },

    async testImage(picture) {
      this.loading = true;
      console.log(this.testFile);
      let reader = new FileReader();
      reader.readAsDataURL(this.testFile[0]);
      reader.onload = async () => {
        let testImageFile = reader.result;
        console.log(testImageFile);
        let generated = (
          await PictureService.generateImage(picture._id, {
            image: testImageFile,
          })
        ).data;
        //download image
        let link = document.createElement("a");
        link.download = "generated" + picture._id + ".png";
        link.href = generated;
        link.click();
        //show image

        picture.image = generated;
        this.loading = false;
      };
    },
  },
};
</script>

<style>
</style>