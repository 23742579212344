<template>
  <canvas id="canvas"></canvas>
</template>

<script>

import EventBus from "@/event-bus";

export default {
  name: "SvgArtboard",


  data() {
    return {
      svg: null,
      unload: false,
      textElements: null,
      render: false,
      darkMode: false,
    };
  },

  mounted() {
    this.run();
  },

  beforeDestroy() {
    console.log("unmounted artboard");
    this.unload = true;
  },


  methods: {

    updateSVG(svg,textElements = null) {
      this.svg = svg;
      this.textElements = textElements;
    },

    changeDarkmode(isDarkmode) {
      this.darkMode = isDarkmode;
      this.render = true;
    },


    run() {

      EventBus.$on("SVG_EDITED", () => {
        setTimeout(() => {
          this.render = true;
        }, 100);
        this.render = true;
      });

      window.onbeforeunload = () => {
        this.unload = true;
        console.log("unmounted artboard");
      };

      window.onresize = () => {
        this.render = true;
      };

      let canvas = document.getElementById("canvas");
      let ctx = canvas.getContext("2d");

      let cameraOffset = {
        x: 0,
        y: 0,
      };
      let cameraZoom = 0.3;
      let MAX_ZOOM = 5;
      let MIN_ZOOM = 0.1;
      let SCROLL_SENSITIVITY = 0.0005;
      let self = this;



      function draw() {
        if (self.unload) return;
        if (!self.render) return requestAnimationFrame(draw);
        if (!self.svg) return requestAnimationFrame(draw);

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        // Translate to the canvas centre before zooming - so you'll always zoom on what you're looking directly at
        ctx.translate(window.innerWidth / 2, window.innerHeight / 2);
        ctx.scale(cameraZoom, cameraZoom);
        ctx.translate(
          -window.innerWidth / 2 + cameraOffset.x,
          -window.innerHeight / 2 + cameraOffset.y
        );

        
        ctx.clearRect(0, 0, window.innerWidth, window.innerHeight); // fill the entire canvas with white


        if (self.svg) {

          let image = new Image();
          image.src = "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(self.svg)));
          //draw svg
          //draw white background
          ctx.fillStyle = self.darkMode ? "#000000" : "#ffffff";
          ctx.fillRect(0, 0, image.width, image.height);

          //reduce image quality to improve performance
          ctx.imageSmoothingEnabled = false;
          ctx.mozImageSmoothingEnabled = false;
          ctx.webkitImageSmoothingEnabled = false;
          ctx.msImageSmoothingEnabled = false;


          ctx.drawImage(image, 0, 0)

          //draw width and height measurement lines and text to the sides
          ctx.strokeStyle = "magenta";
          ctx.lineWidth = image.height / 100;
          ctx.beginPath();
          ctx.moveTo(-(image.height / 100), 0);
          ctx.lineTo(-(image.height / 100), image.height);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(0, -(image.height / 100));
          ctx.lineTo(image.width, -(image.height / 100));
          ctx.stroke();

          ctx.fillStyle = "magenta";
          ctx.font =  image.width / 20 + "px Arial";
          ctx.fillText(image.width, image.width / 2 + 5, -((image.height / 100) * 2));
          //rotate text 90 degree
          ctx.rotate(90 * Math.PI / 180);
          ctx.fillText(image.height, image.height / 2 + 5, ((image.width / 100) * 8));

          //unrotate ctx
          ctx.rotate(-90 * Math.PI / 180);
          

          //draw box around text elements if element.attributes.maxwidth and x, y is set
          if (self.textElements) {
            self.textElements.forEach((element) => {


              //draw Y axis line of element
              if (element.attributes.x && element.attributes.y) {
                ctx.strokeStyle = "red";
                ctx.lineWidth = 2;
                ctx.beginPath();
                const x = parseFloat(element.attributes.x.replace("px",""));
                const y = parseFloat(element.attributes.y.replace("px",""));
                ctx.moveTo(x, 0);
                ctx.lineTo(x, image.height);
                ctx.stroke();

                //draw X axis line of element
                ctx.strokeStyle = "red";
                ctx.lineWidth = 2;
                ctx.beginPath();
                ctx.moveTo(0, y);
                ctx.lineTo(image.width, y);
                ctx.stroke();

              }


              if (element.attributes.maxwidth && element.attributes.maxheight && element.attributes.x && element.attributes.y && element.attributes.fontSize) {
                ctx.strokeStyle = "magenta";
                ctx.lineWidth = 2;
                ctx.beginPath();
                const width = parseInt(element.attributes.maxwidth);
                const height = parseInt(element.attributes.fontSize);
                
                const x = parseFloat(element.attributes.x.replace("px","")) - width / 2;
                const y = parseFloat(element.attributes.y.replace("px","")) - height /2;
                console.log("Draw Textbox Rect", x, y, width, height)
                ctx.rect(x, y, width, height);
                ctx.stroke();
              }

              //draw minwidth and minheight blue rect
              if (element.attributes.minwidth && element.attributes.minheight && element.attributes.x && element.attributes.y && element.attributes.fontSize) {
                ctx.strokeStyle = "blue";
                ctx.lineWidth = 2;
                ctx.beginPath();
                const width = parseInt(element.attributes.minwidth);
                const height = parseInt(element.attributes.fontSize);
                
                const x = parseFloat(element.attributes.x.replace("px","")) - width / 2;
                const y = parseFloat(element.attributes.y.replace("px","")) - height /2;
                console.log("Draw Textbox Rect", x, y, width, height)
                ctx.rect(x, y, width, height);
                ctx.stroke();
              }
      
            });
          }





          self.render = false;
        }

        return requestAnimationFrame(draw);
      }

      // Gets the relevant location from a mouse or single touch event
      function getEventLocation(e) {
        if (e.touches && e.touches.length == 1) {
          return { x: e.touches[0].clientX, y: e.touches[0].clientY };
        } else if (e.clientX && e.clientY) {
          return { x: e.clientX, y: e.clientY };
        }
      }

      let isDragging = false;
      let dragStart = { x: 0, y: 0 };

      function onPointerDown(e) {
        isDragging = true;
        dragStart.x = getEventLocation(e).x / cameraZoom - cameraOffset.x;
        dragStart.y = getEventLocation(e).y / cameraZoom - cameraOffset.y;
      }

      function onPointerUp() {
        isDragging = false;
        initialPinchDistance = null;
        lastZoom = cameraZoom;
      }

      function onPointerMove(e) {
        if (isDragging) {
          cameraOffset.x = getEventLocation(e).x / cameraZoom - dragStart.x;
          cameraOffset.y = getEventLocation(e).y / cameraZoom - dragStart.y;
          self.render = true;
        }
      }

      function handleTouch(e, singleTouchHandler) {
        if (e.touches.length == 1) {
          singleTouchHandler(e);
        } else if (e.type == "touchmove" && e.touches.length == 2) {
          isDragging = false;
          handlePinch(e);
          self.render = true;
        }
      }

      let initialPinchDistance = null;
      let lastZoom = cameraZoom;

      function handlePinch(e) {
        e.preventDefault();
        self.render = true;

        let touch1 = { x: e.touches[0].clientX, y: e.touches[0].clientY };
        let touch2 = { x: e.touches[1].clientX, y: e.touches[1].clientY };

        // This is distance squared, but no need for an expensive sqrt as it's only used in ratio
        let currentDistance =
          (touch1.x - touch2.x) ** 2 + (touch1.y - touch2.y) ** 2;

        if (initialPinchDistance == null) {
          initialPinchDistance = currentDistance;
        } else {
          adjustZoom(null, currentDistance / initialPinchDistance);
        }
      }

      function adjustZoom(zoomAmount, zoomFactor) {
        if (!isDragging) {
          if (zoomAmount) {
            cameraZoom += zoomAmount;
          } else if (zoomFactor) {
            cameraZoom = zoomFactor * lastZoom;
          }

          cameraZoom = Math.min(cameraZoom, MAX_ZOOM);
          cameraZoom = Math.max(cameraZoom, MIN_ZOOM);
          self.render = true;

        }
      }

      canvas.addEventListener("mousedown", onPointerDown);
      canvas.addEventListener("touchstart", (e) =>
        handleTouch(e, onPointerDown)
      );
      canvas.addEventListener("mouseup", onPointerUp);
      canvas.addEventListener("touchend", (e) => handleTouch(e, onPointerUp));
      canvas.addEventListener("mousemove", onPointerMove);
      canvas.addEventListener("touchmove", (e) =>
        handleTouch(e, onPointerMove)
      );
      canvas.addEventListener("wheel", (e) =>
        adjustZoom(e.deltaY * SCROLL_SENSITIVITY)
      );

      // Ready, set, go
      self.render = true;
      draw();
    },
  },
};
</script>

<style scoped>
#canvas {
  width: 100%;
  height: 100%;
  background: url('../assets/gridbg.png') repeat;
}
</style>