//import { parse } from "svg-parser"

import convert from "xml-js"

class SVGParser {

    textElements = [];
    constructor(svgFile = null) {
        if (svgFile) {
            this.data = svgFile;
            this.process();
        }

        this.idCounter = 0;

    }

    setSvgFile(svgFile) {
        this.data = svgFile;
        this.process();
    }

    process() {

        this.idCounter = 0;
        this.textElements = [];
        this.imageElements = [];
        this.genericElements = [];
        //First convert xml 2 json

        let xmlJSON = convert.xml2js(this.data);

        //now  cycle  through every child until its deepest depth and give IDs everywhere
        this.processChilds(xmlJSON)

        this.data = convert.js2xml(xmlJSON)

        console.log("PROCCESSING DONE")


    }

    processChilds(child) {
        if (child.attributes) {
            if (child.type == "element" && child.name == "text") {
                if (!child.attributes) child.attributes = {};
                if (!child.attributes.id) child.attributes.id = "Text-" + this.getId();
                this.textElements.push(child);
            } else if (child.type == "element" && child.name == "image") {
                if (!child.attributes) child.attributes = {};
                if (!child.attributes.id) child.attributes.id = "Image-" + this.getId();
                console.log("Found Image", child)
                this.imageElements.push(child);
            } else {
                //check if id is already set
                if (!child.attributes.id) child.attributes.id = "Element-" + this.getId();
                this.genericElements.push(child)
            }
        }
        if (child.elements) {
            for (let c of child.elements) {
                this.processChilds(c)
            }
        }
    }

    findElement(child, id) {
        if (child.attributes && child.attributes.id == id) {
            return child;
        }
        if (child.elements) {
            for (let c of child.elements) {
                let found = this.findElement(c, id);
                if (found) return found;
            }
        }
        return null;
    }


    updateSVG(svgData) {
        this.data = svgData;

        let xmlJSON = convert.xml2js(this.data);

        //update text elements
        for (let textElement of this.textElements) {
            let id = textElement.attributes.id;
            let newTextElement = this.findElement(xmlJSON, id);

            if (newTextElement) {
                textElement.attributes = newTextElement.attributes;
            } else {
                console.log("Text element not found", id)
            }
        }
        //update image elements
        for (let imageElement of this.imageElements) {
            let id = imageElement.attributes.id;
            let newimageElement = this.findElement(xmlJSON, id);

            if (newimageElement) {
                imageElement.attributes = newimageElement.attributes;
            } else {
                console.log("Text element not found", id)
            }
        }

        //update generic elements
        for (let genericElement of this.genericElements) {
            let id = genericElement.attributes.id;
            let newGenericElement = this.findElement(xmlJSON, id);

            if (newGenericElement) {
                genericElement.attributes = newGenericElement.attributes;
            } else {
                console.log("element not found", id)
            }
        }


    }

    renameNodeId(oldId, newId) {
        //this function should rename a node id and update all references to it
        //first find the node

        //now update all references to it
        for (let textElement of this.textElements) {
            if (textElement.attributes.id == oldId) {
                textElement.attributes.id = newId;
            }
        }
        for (let imageElement of this.imageElements) {
            if (imageElement.attributes.id == oldId) {
                imageElement.attributes.id = newId;
            }
        }

        for (let genericElement of this.genericElements) {
            if (genericElement.attributes.id == oldId) {
                genericElement.attributes.id = newId;
            }
        }

    }

    getId() {
        this.idCounter++;
        return this.idCounter
    }

    getTextElements() {
        return this.textElements;
    }
    getImageElements() {
        return this.imageElements;
    }

    getGenericElements() {
        return this.genericElements;
    }

    getElementIds() {
        let ids = [];
        for (let textElement of this.textElements) {
            ids.push(textElement.attributes.id);
        }
        for (let imageElement of this.imageElements) {
            ids.push(imageElement.attributes.id);
        }
        for (let genericElement of this.genericElements) {
            ids.push(genericElement.attributes.id);
        }

        return ids;
    }

    getJson() {
        return this.json;
    }

    getSVGFile() {
        return this.data;
    }


}

export default SVGParser;