<template>
    <v-container>

        <!-- link /assets/configurator.css -->
        <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAxq2cu9y7QJPxhZbg85MRqupA_MlhMBWY&callback=initAutocomplete&libraries=places&v=weekly" defer=""></script>
        

        
        <v-row>
            <v-col cols="12">
                <h1>Configurator Preview</h1> <br>
                <p>Here you can see the preview of your configurator. </p>
                <v-btn @click="clearCache()">Clear Cache</v-btn>

                <!-- Select SKU -->
                <v-autocomplete :items="skus" label="Select SKU" v-model="selectedSKU" outlined dense class="mt-4" ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>

            <v-col cols="6">
                <!-- Preview -->
                <img id="poster-preview" style="
                        height: auto;
                        width: 100%;
                        border:  0px
                    " />
                <div id="poster-loading" class="lds-ripple">
                    <div></div>
                    <div></div>
                </div>

            </v-col>
            <v-col cols="6">

                <v-card v-if="selectedSKU">
                    <v-card-title>
                        <h3>Configurator: {{ selectedSKU }}</h3>
                    </v-card-title>

                    <v-card-text>
                        <div id="poster-options">

                        </div>

                        <v-btn @click="downloadPDF()">Download</v-btn>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>

</template>

<script>

//load /assets/configurator.js
import AcceleryConfigurator from '../assets/configurator.js'
import PosterService from '../services/PosterService'
import DesignService from '../services/DesignService'
var configurator = new AcceleryConfigurator();



export default {
    name: 'ConfiguratorPreview',
    components: {

    },
    data() {
        return {
            selectedSKU: null,
            skus: [],
            search: '',
        }
    },
    methods: {

        async loadSkus() {
            //load skus from configurator.js
            let posters = (await PosterService.getPosters()).data
            this.skus = posters.map(poster => poster.SKU)
        },

        async downloadPDF() {
            const designId = configurator.getDesignId();
            this.loading = true;
            const poster = (await DesignService.generateOrder(designId)).data
            let pdfURL = poster.options.pdf;
            //open url in new tab
            window.open(pdfURL, '_blank');
            this.loading = false;
        }, 

        async clearCache() {
            this.loading = true; 
            await PosterService.clearCache();
            this.loading = false;
        }

    },
    computed: {

    },

    //Watch if selectedSKU changes

    watch: {
        selectedSKU: function (val) {
            if (val) {
                //load configurator.js
                configurator.setSku(val)
            }
        }
    },
    
    mounted() {
        this.loadSkus()
    }
}

</script>

<style>

    @import '../assets/configurator.css';


    .product-form__input {
        background: white;
        padding: 8px;
    }
</style>