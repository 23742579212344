<template>
    <div></div>
</template>

<script>

import AuthService from '../services/AuthService'
export default {
    data: () => ({
        user: null,
    }),
    async created() {
        try {
            this.user = (await AuthService.getUser()).data
            console.log(this.user)
        } catch(err) {
            console.error(err)
            if(!this.user) this.$router.push('/auth/login');
        }
    },
}
</script>

<style>

</style>