<template>
    <div>
      <v-container>
        <v-alert type="error" v-if="error" :value="true">{{ error }}</v-alert>
  
        <v-row>
          <v-col cols="10">
            <v-text-field
              v-model="search"
              label="Suche"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        
        </v-row>
       
        <v-row>
          <!-- Vuetify Data Table to show all posters-->
          <v-col v-for="design of designs" :key="design.designId" cols="4">
            <v-card :loading="loading">
              <v-img
                :src="design.poster"
                height="auto"
                width="100%"
                comtain
              ></v-img>
              <v-card-actions>
                <v-btn color="error" @click="deleteItem(design)">Löschen</v-btn>
              </v-card-actions>
              <v-overlay :value="loading">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-overlay>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <AuthCheck />
    </div>
  </template>
  
  <script>
  import DesignService from "../../services/DesignService";
  import AuthCheck from "../../components/AuthCheck.vue";
  import AuthService from "../../services/AuthService";
  
  export default {
    components: { AuthCheck },
    data: () => ({
      designs: [],
      loading: false,
      error: null,
      user: null,
      search: "",
      testFile: null,
    }),
    async created() {
      try {
        this.user = (await AuthService.getUser()).data;
      } catch (err) {
        console.error(err);
        if (!this.user) this.$router.push("/auth/login");
      }
    },
    async mounted() {
      this.loading = true;
      try {
        this.designs = (await DesignService.getDesigns()).data;
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
    methods: {
      async deleteItem(item) {
        this.loading = true;
        try {
          await DesignService.deleteDesign(item.designId);
          this.designs = this.designs.filter(
            (design) => design.designId !== item.designId
          );
        } catch (err) {
          this.error = err;
        } finally {
          this.loading = false;
        }
      },
  
     
    },
  };
  </script>
  
  <style>
  </style>